import React, { ReactElement } from 'react';
import { Box } from 'theme-ui';

interface CardProps {
  title: string;
  description: string;
  stepNum: number;
}

const StepCard = ({ title, description, stepNum }: CardProps): ReactElement => {
  return (
    <Box
      sx={{
        backgroundColor: 'background',
        boxShadow: '1.52126px 3.04253px 9.12758px rgba(0, 0, 0, 0.05)',
        borderRadius: '9px',
        flex: '1 1 0%',
        maxWidth: '200px',
        padding: '12px',
        paddingTop: '28px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          background: 'linear-gradient(180deg, #174bad 0%, #75a6ff 100%)',
          width: '80px',
          height: '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          position: 'absolute',
          transform: 'translateY(-100%)',
        }}
      >
        <Box
          as="span"
          sx={{
            fontWeight: 700,
            fontSize: '48px',
            lineHeight: '58px',
            textAlign: 'center',
            letterSpacing: '0.427124px',
            textTransform: 'capitalize',
            color: '#fff',
          }}
        >
          {stepNum}
        </Box>
      </Box>
      <Box
        as="h4"
        sx={{
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '30px',
          letterSpacing: 0.22819,
          textTransform: 'capitalize',
          color: 'text',
          marginY: '9px',
        }}
      >
        {title}
      </Box>
      <Box
        as="p"
        sx={{
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '15px',
          color: 'sprText02',
        }}
      >
        {description}
      </Box>
    </Box>
  );
};

export default StepCard;
