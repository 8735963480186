import React, { ReactElement } from 'react';
import { Flex, Box } from 'theme-ui';
import { HighlightableTitle } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { HighlightableTitleProps } from '@sprinklr/shared-lib/@types';
import StepCard from './components/StepCard';

interface Props {
  highlightableContentTitle: HighlightableTitleProps;
  list: {
    title: string;
    description: string;
  }[];
  topMargin?: string;
  bottomMargin: string;
}

const StepListTemplate = ({
  highlightableContentTitle,
  list,
  topMargin,
  bottomMargin,
}: Props): ReactElement => {
  const [pt, pb] = useContainerPadding({
    top: topMargin || 'X-SMALL',
    bottom: bottomMargin || 'X-SMALL',
  });

  return (
    <Box
      sx={{
        pt,
        pb,
        px: '10px',
      }}
    >
      <HighlightableTitle
        title={highlightableContentTitle?.title}
        description={highlightableContentTitle?.description}
        icon={highlightableContentTitle.icon}
      />
      <Flex
        sx={{
          marginY: 5,
          gap: ['80px', '80px', '30px', '30px'],
          flexDirection: ['column', 'column', 'row', 'row'],
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {list.map((item, idx) => (
          <StepCard key={item.title} {...item} stepNum={idx + 1} />
        ))}
      </Flex>
    </Box>
  );
};

export default StepListTemplate;
